import { Link, Toolbar, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import * as React from "react";

export default function SocialMediaNavigation() {
  function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://orion-vetting.com/">
          Orion Vetting
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <Toolbar
      variant="dense"
      sx={{
        position: "absolute",
        bottom: "15px",
        left: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        px: [1],
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: "5px",
        }}
      >
        <a
          href="https://www.linkedin.com/in/mate-turcinov-3626142a/"
          target="_blank"
        >
          <LinkedInIcon sx={{ color: "white" }} />
        </a>
      </Container>
      <Copyright sx={{ color: "#f0f0f1", display: "block" }} />
    </Toolbar>
  );
}
