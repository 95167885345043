import {
  Typography,
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Fab,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import mateAvatar from "../images/webp/mate-avatar.webp";
import ceoIntro from "../images/webp/ceo-intro.webp";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const buttonSx = {
  color: "white",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#12a79d",
  },
  my: 2,
  width: { xs: "100%", sm: "60%", md: "100%" },
};

const buttonFilledSx = {
  my: 2,
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function AboutUs() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            About Us.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="h5"
                  color="white"
                  my={2}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">Introduction</span>
                  </span>
                </Typography>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 2, sm: 2 },
                  }}
                >
                  Orion Vetting is an independent marine surveying, inspection
                  and consulting company based in Rijeka, Croatia. Our surveyors
                  are all highly skilled, qualified marine professionals and ex.
                  seafarers with remarkable track records in the marine
                  transportation industry. Within our team of experts, we have
                  accumulated experience and know-how from both sides of
                  shipping business - the ship management sector as well as
                  marine regulatory organizations (flag states, classification
                  societies, oil majors).
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",

                    px: { xs: 2, sm: 2 },
                  }}
                >
                  Orion Vetting was founded in 2023 by marine transport engineer
                  Mate Turčinov. The company is fully committed to constantly
                  improve the safety and quality performance of marine bulk
                  transportation and provide shipowners, recognized
                  organizations and other marine institutions with cost
                  effective systems for risk assessment, thus protecting their
                  assets.
                </Typography>
              </Grid>
              <Grid item xs={12} mt={5}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">Founder & CEO</span>
                  </span>
                </Typography>
                <Container sx={{ my: 4 }} maxWidth="xl">
                  <Grid container columnSpacing="20">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={4}
                      sx={{
                        textAlign: { xs: "center", sm: "center", md: "left" },
                      }}
                    >
                      <Item
                        sx={{
                          p: 3,
                          my: { xs: 5, sm: 5, md: 0, lg: 0 },
                          textAlign: { xs: "center", sm: "center", md: "left" },
                          mt: { xs: 0, sm: 0 },
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: {
                              xs: "center",
                              sm: "center",
                              md: "center",
                            },
                          }}
                        >
                          <Typography variant="h5" color="white">
                            Mate Turčinov
                          </Typography>
                          <Box
                            component="img"
                            sx={{
                              p: 1,
                              width: {
                                xs: "100%",
                                sm: "60%",
                                md: "80%",
                                lg: "80%",
                              },
                              borderRadius: 4,
                              textAling: "center",
                            }}
                            alt="CEO avatar"
                            src={mateAvatar}
                          />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                          <Typography variant="subtitle1" color="white">
                            <span
                              className="ceo-subtitle"
                              style={{ fontWeight: "bold" }}
                            >
                              E-mail:
                            </span>{" "}
                            m.turcinov@orion-vetting.com
                          </Typography>
                          <Typography variant="subtitle1" color="white">
                            <span
                              className="ceo-subtitle"
                              style={{ fontWeight: "bold" }}
                            >
                              Phone:
                            </span>{" "}
                            +385 91 7363 524
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="white"
                            sx={{
                              textAlign: "center",
                              display: { xs: "grid", sm: "grid", md: "flex" },
                              justifyContent: {
                                xs: "center",
                                sm: "center",
                                md: "left",
                              },
                            }}
                          >
                            <span
                              style={{ marginRight: "5px", fontWeight: "bold" }}
                            >
                              Social:
                            </span>
                            <a
                              href="https://www.linkedin.com/in/mate-turcinov-3626142a/"
                              target="_blank"
                              style={{ textAlign: "center" }}
                            >
                              {" "}
                              <LinkedInIcon
                                fontSize="medium"
                                sx={{ color: "white" }}
                              />
                            </a>
                          </Typography>
                          <Button
                            variant="outlined"
                            fullWidth={true}
                            sx={buttonSx}
                            href={
                              "https://orion-vetting.com/files/MATE_TURCINOV_CV.pdf"
                            }
                            target="_blank"
                          >
                            Download CV
                          </Button>
                        </Box>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={8}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        color="white"
                        sx={{
                          textAlign: "justify",
                          px: { xs: 0, sm: 0, md: 3 },
                        }}
                      >
                        Mate began working ashore for German shipowner Bernhard
                        Schulte Shipmanagement in 2008, in the department for
                        Loss, Prevention, Safety and Quality. Throughout his
                        professional career Mate worked for several reputable
                        ship management companies in Hamburg, Germany, where he
                        continuously developed his skills and gained specialized
                        chemical tanker experience. In 2014 Mate joined the
                        leading European chemical tanker company John T.
                        Essberger, where he first worked as Onboard Ship
                        Trainer, and later took over the responsibility of
                        Designated Person Ashore, Company Security Officer and
                        SSHEQ Superintendent. After 13 years abroad, Mate
                        returned to Croatia in 2020 to lead a project for
                        Croatian-based shipping company in the dry-bulk sector.
                        In 2023 Mate was accredited a flag state inspector and
                        independent marine surveyor/auditor.
                      </Typography>
                      {windowSize.innerWidth > 1000 ? (
                        <Box sx={{ textAlign: "center" }}>
                          <Box
                            component="img"
                            sx={{
                              mt: { xs: 2, sm: 3 },
                              mb: { xs: 2, sm: 3 },
                              width: {
                                xs: "90%",
                                sm: "70%",
                                md: "70%",
                                lg: "80%",
                              },
                              borderRadius: 2,
                            }}
                            alt="CEO INTRO IMG"
                            src={ceoIntro}
                          />
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{ textAlign: { xs: "center", sm: "center", md: "left" } }}
                >
                  <span className="subheading">
                    <span className="subheading">Our Team</span>
                  </span>
                </Typography>
                <Container sx={{ my: 2 }} maxWidth="xl">
                  {/* End hero unit */}
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        color="white"
                        sx={{
                          textAlign: "justify",
                        }}
                      >
                        Orion Vetting team consists of two additional vetting
                        inspectors/master mariners and a technical
                        superintendent for chemical tankers. Please contact us
                        for more information regarding the availability of our
                        surveyors.
                      </Typography>
                      <Button
                        variant="contained"
                        sx={buttonFilledSx}
                        size="large"
                        onClick={() => navigate("/contact")}
                      >
                        Contact Us
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{ textAlign: { xs: "center", sm: "center", md: "left" } }}
                >
                  <span className="subheading">
                    <span className="subheading">Our Mission & Vision</span>
                  </span>
                </Typography>
                <Container sx={{ my: 2 }} maxWidth="xl">
                  <Typography
                    color="white"
                    sx={{
                      textAlign: "justify",

                      pb: 3,
                    }}
                  >
                    Our mission is to provide customers with excellent and
                    reliable services which meet and exceed their needs and
                    expectations. Our vision is to be the automatic first choice
                    for our customers by continually developing and improving
                    our services through our long-lasting expertise, innovative
                    and pioneering solutions, aiming always for perfection and
                    excellence.
                  </Typography>
                  <Grid
                    container
                    spacing={5}
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",

                          justifyContent: "center",
                        }}
                      >
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            sx={{
                              textAlign: "center",

                              my: 1,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCompass}
                              size="xl"
                              color="#12a79d"
                            />
                          </Typography>
                          <Typography
                            variant="h6"
                            component="h2"
                            sx={{
                              mt: 1,
                              textAlign: "center",
                              fontWeight: "800",
                            }}
                          >
                            Our Services
                          </Typography>
                          <Button
                            variant="outlined"
                            sx={buttonFilledSx}
                            size="large"
                            onClick={() => navigate("/our-services")}
                          >
                            Learn More
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={5}
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <Box sx={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          sx={buttonSx}
                          href={
                            "https://orion-vetting.com/files/ORION_VETTING_PORTRAIT.pdf"
                          }
                          target="_blank"
                        >
                          Download Company Portrait
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
        <Fab
          size="medium"
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
            backgroundColor: "#138c84",
            "&:hover": {
              bgcolor: "#12a79d",
            },
          }}
          onClick={() => navigate("/contact")}
        >
          <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
        </Fab>
      </div>
    </PageTransition>
  );
}
