import {
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  Box,
  CardContent,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  faPaperPlane,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "@emailjs/browser";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Fade from "@mui/material/Fade";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Contact() {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<number>();
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [messageError, setMessageError] = React.useState<boolean>(false);

  const [snackSuccess, setSnackSuccess] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const [snackError, setSnackError] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handleClick = () => {
    let nameError1 = false;
    let emailError1 = false;
    let messageError1 = false;

    if (name.length === 0) {
      nameError1 = true;
      setNameError(true);
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      emailError1 = true;
      setEmailError(true);
    }

    if (message.length === 0) {
      messageError1 = true;
      setMessageError(true);
    }
  };

  const handleCloseSnackSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackSuccess({
      ...snackSuccess,
      open: false,
    });
  };

  const handleCloseSnackError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackError({
      ...snackError,
      open: false,
    });
  };

  const buttonSx = {
    ...(success && {
      color: "white",

      "&:hover": {
        bgcolor: "#12a79d",
      },
    }),
    color: "white",
    borderColor: "#12a79d",
    "&:hover": {
      bgcolor: "#12a79d",
    },
  };

  const sendEmail =
    (
      Transition: React.ComponentType<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    ) =>
    (e: any) => {
      e.preventDefault();

      let nameError = false;
      let emailError = false;
      let messageError = false;

      if (name.length === 0) {
        nameError = true;
        setNameError(true);
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        emailError = true;
        setEmailError(true);
      }

      if (message.length === 0) {
        messageError = true;
        setMessageError(true);
      }

      if (!loading) {
        setLoading(true);
        if (!nameError && !emailError && !messageError) {
          timer.current = window.setTimeout(() => {
            emailjs
              .sendForm(
                "service_mch34al",
                "template_m3vrs7i",
                e.target,
                "IUrjNX2RnrTdDsr2a"
              )
              .then(
                () => {
                  setSnackSuccess({
                    open: true,
                    Transition,
                  });
                  setLoading(false);
                  setName("");
                  setEmail("");
                  setMessage("");
                },
                (error) => {
                  setSnackError({
                    open: true,
                    Transition,
                  });
                }
              );
          }, 1000);
        } else {
          timer.current = window.setTimeout(() => {
            setLoading(false);
            setSnackError({
              open: true,
              Transition,
            });
          }, 500);
        }
      }
    };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            Contact.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                <span className="subheading">
                  <span className="subheading">Contact Form</span>
                </span>
              </Typography>
              <Box
                component="form"
                sx={{
                  mt: { xs: 3, sm: 3, md: 3 },
                  mb: { xs: 5, sm: 5, md: 5 },
                  px: { xs: 2, sm: 3, md: 15 },
                }}
                onSubmit={sendEmail(SlideTransition)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={nameError}
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      autoFocus
                      value={name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value.length === 0) {
                          setNameError(true);
                        } else {
                          setNameError(false);
                        }
                        setName(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={emailError}
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            event.target.value
                          )
                        ) {
                          setEmailError(true);
                        } else {
                          setEmailError(false);
                        }
                        setEmail(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={messageError}
                      required
                      fullWidth
                      id="message"
                      label="Enter your message here"
                      name="message"
                      multiline
                      rows={6}
                      value={message}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value.length === 0) {
                          setMessageError(true);
                        } else {
                          setMessageError(false);
                        }
                        setMessage(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mt: 2, position: "relative" }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={buttonSx}
                    disabled={loading}
                    fullWidth={true}
                    onClick={handleClick}
                  >
                    Send E-mail
                  </Button>
                  <Snackbar
                    autoHideDuration={4000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={snackSuccess.open}
                    onClose={handleCloseSnackSuccess}
                    TransitionComponent={snackSuccess.Transition}
                    message="E-mail sent successfully!"
                    key={"snackSuccess"}
                  >
                    <Alert
                      onClose={handleCloseSnackSuccess}
                      severity="success"
                      sx={{ width: "100%", color: "white" }}
                    >
                      E-mail has been sent successfully!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    autoHideDuration={4000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={snackError.open}
                    onClose={handleCloseSnackError}
                    TransitionComponent={snackError.Transition}
                    message="Failed to send email!"
                    key={"snackError"}
                  >
                    <Alert
                      onClose={handleCloseSnackError}
                      severity="error"
                      sx={{ width: "100%", color: "white" }}
                    >
                      Failed to send email!
                    </Alert>
                  </Snackbar>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#12a79d",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  textAlign: "center",

                  color: "white",
                  pt: 3,
                }}
              >
                <span className="subheading">
                  <span className="subheading">Head Office</span>
                </span>
              </Typography>
              <Container sx={{ pt: 2, pb: 8 }} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          sx={{
                            textAlign: "center",

                            mt: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPaperPlane}
                            size="xl"
                            color="#12a79d"
                          />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          sx={{
                            textAlign: "center",

                            fontWeight: "800",
                            mt: 3,
                          }}
                        >
                          office@orion-vetting.com
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          sx={{
                            textAlign: "center",

                            mt: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            size="xl"
                            color="#12a79d"
                          />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          sx={{
                            textAlign: "center",

                            fontWeight: "800",
                            mt: 3,
                          }}
                        >
                          Dubrovačka 2,
                          <div>51000 Rijeka</div>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          sx={{
                            textAlign: "center",

                            mt: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPhone}
                            size="xl"
                            color="#12a79d"
                          />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          sx={{
                            textAlign: "center",

                            fontWeight: "800",
                            mt: 3,
                          }}
                        >
                          +385 91 7363 524
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>

              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  textAlign: "center",

                  color: "white",
                }}
              >
                <span className="subheading">
                  <span className="subheading">Our Location</span>
                </span>
              </Typography>
              <Box sx={{ mt: 3, mb: 5, mx: { xs: 2, sm: 2, md: 10 } }}>
                <Card sx={{ height: 300 }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2804.431625131455!2d14.4140915!3d45.3400987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4764a6d29380a7bb%3A0x287001e05bdb262b!2sDubrova%C4%8Dka%20ul.%202%2C%2051000%2C%20Rijeka!5e0!3m2!1sen!2shr!4v1685190698579!5m2!1sen!2shr"
                    style={{ border: "0" }}
                    width="100%"
                    height="300"
                    loading="lazy"
                  ></iframe>
                </Card>
              </Box>

              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  textAlign: "center",

                  color: "white",
                }}
              >
                <span className="subheading">
                  <span className="subheading">Company Info</span>
                </span>
              </Typography>
              <Box sx={{ mt: 3, mb: 5, mx: { xs: 2, sm: 2, md: 10 } }}>
                <Card
                  sx={{
                    textAlign: "center",

                    p: 5,
                  }}
                >
                  <div>Kornatska 8, </div>
                  <div>22243 Murter, Croatia </div>
                  <div>MBO: 15010011043 </div>
                  <div>OIB: 11965192834</div>
                </Card>
              </Box>
            </Box>
          </ThemeProvider>
        </Container>
      </div>
    </PageTransition>
  );
}
