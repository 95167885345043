import * as React from "react";
import { motion } from "framer-motion";

const ButtonAnimation = ({ children }: any) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 3.5, duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default ButtonAnimation;
