import * as React from "react";
import { Box, Button, Fab } from "@mui/material";
import PageTransition from "./PageTransition";
import WavyText from "./WavyText";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import shipyardMainImage from "../images/webp/shipyard-home.webp";
import ButtonAnimation from "./ButtonAnimation";

export default function Home() {
  const [replay, setReplay] = React.useState(true);
  const navigate = useNavigate();

  const buttonGetStartedSx = {
    my: 2,
    color: "white",
    backgroundColor: "#12a79d",
    borderColor: "#00ffef",
    "&:hover": {
      bgcolor: "#00948b",
    },
  };

  return (
    <PageTransition>
      <Box
        sx={{
          maxHeight: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "100%",
            textAlign: "center",
            display: "block",
            transform: "translateY(-50%)",
            zIndex: "2",
          }}
        >
          <Box
            sx={{
              fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
              fontWeight: "300",
              color: "#e0f1ff",
            }}
          >
            <WavyText
              text="Orion"
              replay={replay}
              delay={0.5}
              style={{ paddingRight: 20 }}
            />
            <WavyText
              text="Vetting"
              replay={replay}
              delay={0.7}
              color="#0bccc0"
            />
          </Box>
          <Box
            sx={{
              ml: { xs: 0, sm: 20, md: 30 },
              fontSize: { xs: "1rem", sm: "1rem", md: "1.7rem" },
              fontWeight: "300",
            }}
          >
            <WavyText
              text="...tailored marine solutions for your business needs"
              replay={replay}
              delay={0.7}
              color="white"
            />
          </Box>
          <Box
            sx={{
              ml: { xs: 0, sm: 20, md: 30 },
              fontSize: { xs: "1rem", sm: "1rem", md: "1.7rem" },
              fontWeight: "300",
            }}
          >
            <ButtonAnimation>
              <Button
                variant="contained"
                sx={buttonGetStartedSx}
                size="large"
                onClick={() => navigate("/about-us")}
              >
                Get Started
              </Button>
            </ButtonAnimation>
          </Box>
        </Box>
        <Box
          component="img"
          sx={{
            minWidth: "100%",
            height: "100vh",
            background: `url(${shipyardMainImage}) no-repeat center center`,
            backgroundSize: "cover",
            filter: "brightness(60%)",
          }}
        />
      </Box>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
